import { mapState } from 'vuex';
import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnImageDocItem from '../../../shared/components/KnImageDocItem.vue';
import KnImagesGalleryContainer from '../../../shared/components/KnImagesGalleryContainer/KnImagesGalleryContainer.vue';
import KnDocsGalleryContainer from '../../../shared/components/KnDocsGalleryContainer/KnDocsGalleryContainer.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnTextArea from '../../../shared/components/KnTextArea.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import {
  postAnnouncement,
  putAnnouncement,
  resendAnnouncementEmails,
} from '../../helpers/announcementOptions';
import { postDocument, postImage } from '../../../shared/helpers/dataOptions';
import { createFormDataFromObject } from '../../../shared/helpers/dataUtils';
import { fetchGroups } from '../../helpers/reportCardOptions';
import {
  canAdd,
  canChange,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
export default {
  name: 'KnFormAnnouncements',
  components: {
    KnAutoComplete,
    KnBackToTopButton,
    KnCheckBox,
    KnDocItem,
    KnDocsGalleryContainer,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormSubtitle,
    KnFormTitle,
    KnImageDocItem,
    KnImagesGalleryContainer,
    KnLocalAlert,
    KnSelect,
    KnTabs,
    KnTextArea,
    KnTextField,
  },
  mixins: [],
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      valueDeterminate: 100,
      routerName: 'Anuncios',
      resource: 'anuncio',
      valid: true,
      announcement: {
        id: null,
        nombre_anuncio: '',
        contenido: '',
        imagenes_anuncios: [],
        archivos_anuncios: [],
        institucion_educativa: null,
        autor: null, // usuario logueado
        fecha_inicio: null,
        fecha_fin: null,
        es_general: true,
        grupos: [],
        estatus_sistema: true,
      },
      announcementId: null,
      announcementImages: [],
      announcementGroups: [],
      announcementFiles: [],
      selectedImages: [], // ids
      selectedFiles: [], // ids
      selectedGroups: [], // ids
      imagesToAdd: null, // files
      filesToAdd: null, // otros archivos
      groups: [],
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
      sendMail: true,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Agregar anuncio' : 'Editar anuncio';
    },
    isNewMode() {
      return this.entity === null;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Anuncio registrado con exito!'
        : 'Anuncio actualizado con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    isReadonly() {
      return this.isNewMode ? false : !canChange(this.resource);
    },
    warningText() {
      return this.warnings.length ? this.warnings.join(',') : null;
    },
  },
  created() {
    this.loading = true;
    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.showAlert = true;
    this.fetchData();
    if (!this.isNewMode) {
      // console.log('Se deben llenar los campos');
      this.announcementId = this.entity.id;
      this.fillAnnouncement();
    }
    setTimeout(() => (this.showAlert = false), 1000);
  },
  methods: {
    async fetchData() {
      const { ok, data } = await fetchGroups({
        institutionId: this.institutionId,
        systemStatus: true,
        limit: 100,
      });
      this.groups = ok ? data : [];
    },
    async createAnnouncement() {
      try {
        this.alertText = 'Creando anuncio';
        this.announcement.autor = this.userData.id;
        this.announcement.institucion_educativa = this.institutionId;
        const { ok, data, message } = await postAnnouncement(this.announcement);
        if (!ok) {
          this.errors.push(message);
        } else {
          this.announcementId = data.id;
        }
      } catch (error) {
        this.errors.push(error);
        console.error('Error al intentar crear anuncio. ' + error);
      }
    },
    async updateAnnouncement() {
      try {
        this.alertText = 'Actualizando anuncio';
        // console.log('Anuncio a actualizar', this.announcement);
        if (
          this.selectedImages.length !==
          this.announcement.imagenes_anuncios.length
        ) {
          this.announcement.imagenes_anuncios = this.selectedImages;
        }
        if (
          this.selectedFiles.length !==
          this.announcement.archivos_anuncios.length
        ) {
          this.announcement.archivos_anuncios = this.selectedFiles;
        }
        const { ok, data, message } = await putAnnouncement(this.announcement);
        if (!ok) {
          this.errors.push(message);
        } else {
          this.announcementId = data.id;
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar anuncio. ' + error);
        console.error('Error al intentar actualizar anuncio. ' + error);
      }
    },
    async createImages() {
      try {
        this.alertText = 'Creando imágenes';
        if (this.imagesToAdd) {
          for (const file of this.imagesToAdd.file) {
            const image = {
              nombre_imagen: file.name,
              imagen: file,
              // tipo_imagen: 1,
              estatus_sistema: true,
            };
            const imageFormData = createFormDataFromObject(image);
            const { ok, data, message } = await postImage(imageFormData);
            if (ok) {
              this.announcement.imagenes_anuncios.push(data.id);
            } else {
              // console.log('No se pudo crear la imagen. ' + message);
              this.errors.push(message);
            }
          }
        }
      } catch (error) {
        this.errors.push('Error al intentar crear imagenes. ' + error);
      }
    },
    async createFiles() {
      try {
        this.alertText = 'Creando archivos';
        if (this.filesToAdd) {
          for (const file of this.filesToAdd.file) {
            const doc = {
              nombre_documento: file.name,
              documento: file,
              tipo_documento: 3,
              estatus_sistema: true,
              autor: this.userData.id,
            };
            const docFormData = createFormDataFromObject(doc);
            const { ok, data, message } = await postDocument(docFormData);
            if (ok) {
              this.announcement.archivos_anuncios.push(data.id);
            } else {
              // console.log('No se pudo crear la imagen. ' + message);
              this.errors.push(message);
            }
          }
        }
      } catch (error) {
        this.errors.push('Error al intentar crear archivos. ' + error);
      }
    },
    async resendSelectedAnnouncements(announcementId) {
      try {
        this.alertText = 'Enviando correo...';
        // console.log('Anuncios seleccionados', selected);
        const { ok, message } = await resendAnnouncementEmails([
          announcementId,
        ]);
        if (!ok) {
          this.warnings.push(message);
        }
      } catch (error) {
        this.warnings.push('Error al intentar enviar correo. ' + error);
      }
    },
    fillAnnouncement() {
      // Respaldo de imagenes
      this.announcementImages = this.entity.imagenes_anuncios;
      this.selectedImages = this.announcementImages.map((image) => image.id);
      this.announcementFiles = this.entity.archivos_anuncios;
      this.selectedFiles = this.announcementFiles.map((doc) => doc.id);
      // console.log('announcementImages', this.announcementImages);
      // console.log('selectedImages', this.selectedImages);
      // Respaldo de grupos
      this.announcementGroups = this.entity.grupos;
      this.announcement = {
        ...this.entity,
        fecha_inicio: this.entity.fecha_inicio.substring(0, 10),
        fecha_fin: this.entity.fecha_fin.substring(0, 10),
        imagenes_anuncios: this.announcementImages.map((image) => image.id),
        archivos_anuncios: this.announcementFiles.map((doc) => doc.id),
        grupos: this.announcementGroups.map((group) => group.id),
        autor: this.userData.id,
        institucion_educativa: this.institutionId,
      };
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;

        if (this.isNewMode) {
          if (canAdd(this.resource)) {
            await this.createImages();
            await this.createFiles();
            // console.log('A crear');
            if (this.errors.length === 0) {
              await this.createAnnouncement();
            }
            if (this.errors.length === 0 && this.sendMail) {
              await this.resendSelectedAnnouncements(this.announcementId);
            }
            this.completeAlert();
          } else {
            this.insufficientPermissionAlert();
          }
        } else {
          if (canChange(this.resource)) {
            await this.createImages();
            await this.createFiles();
            // console.log('A actualizar');
            if (this.errors.length === 0) {
              await this.updateAnnouncement();
            }
            if (this.errors.length === 0 && this.sendMail) {
              await this.resendSelectedAnnouncements(this.announcementId);
            }
            this.completeAlert();
          } else {
            this.insufficientPermissionAlert();
          }
        }
      }
    },
    completeAlert() {
      this.loading = false;
      if (this.errors.length) {
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = this.successAlertType;
        this.alertColor = 'success';
        this.alertText = this.successAlertText;
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      this.announcement = {
        id: null,
        nombre_anuncio: '',
        contenido: '',
        imagenes_anuncios: [],
        archivos_anuncios: [],
        institucion_educativa: null,
        autor: this.userData.id, // usuario logueado
        fecha_inicio: null,
        fecha_fin: null,
        es_general: true,
        grupos: [],
        estatus_sistema: true,
      };
      this.announcementImages = [];
      this.selectedImages = [];
      this.announcementFiles = [];
      this.selectedFiles = [];
    },
    saveImages(file) {
      // console.log('image a agregar', file);
      this.imagesToAdd = file;
    },
    clearImages() {
      this.imagesToAdd = null;
    },
    saveFiles(file) {
      // console.log('image a agregar', file);
      this.filesToAdd = file;
    },
    clearFiles() {
      this.filesToAdd = null;
    },
  },
};
