<template>
  <v-row :align="columnAlign" :justify="columnJustify">
    <v-col class="py-0" cols="12" md="4" lg="4" xl="4">
      <v-subheader>{{ label }}</v-subheader>
    </v-col>
    <v-col class="py-0" :cols="colBtn">
      <v-avatar class="border" size="80">
        <v-img :src="image" />
      </v-avatar>
    </v-col>
    <v-col class="py-0" :cols="colsActionBtns">
      <v-row class="my-2" :align="columnAlign" :justify="columnJustify">
        <v-divider class="info" vertical />
        <v-btn
          color="info"
          text
          tile
          small
          :disabled="disabled"
          @click="emitDelete"
        >
          <span class="text-none">Eliminar</span>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    doc: {
      type: Object,
      default: () => null,
    },
    label: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: null,
    },
    colBtn: {
      type: String,
      default: '4',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colsActionBtns() {
      return 8 - this.colBtn;
    },
    columnAlign() {
      return 'start';
    },
    columnJustify() {
      return 'start';
    },
  },
  methods: {
    emitDelete() {
      this.$emit('deleteImage');
    },
  },
};
</script>

<style></style>
