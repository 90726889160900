import { getItem, postItem, putItem, deleteItem } from '@/api/api-methods';

export const fetchAnnouncements = async ({
  institutionId = null,
  authorId = null,
  announcementName = null,
  systemStatus = null,
  groupId = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const authorFilter = authorId ? `&autor=${authorId}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const searchFilter =
      announcementName !== null ? `&nombre_anuncio=${announcementName}` : '';
    const groupFilter = groupId !== null ? `&grupos=${groupId}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/anuncios?'.concat(
        institutionFilter,
        authorFilter,
        systemStatusFilter,
        searchFilter,
        groupFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los anuncios. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener anuncios. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener anuncios. ' + error,
      count: 0,
    };
  }
};

export const postAnnouncement = async (announcement) => {
  try {
    const response = await postItem('/app-personas/anuncios', announcement);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear el anuncio. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: 'Error al intentar crear anuncio. ' + error };
  }
};

export const putAnnouncement = async (announcement) => {
  try {
    const { id } = announcement;
    const response = await putItem(
      `/app-personas/anuncios/${id}`,
      announcement
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el anuncio. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar anuncio. ' + error,
    };
  }
};

export const delAnnouncement = async (id) => {
  try {
    const response = await deleteItem('/app-personas/anuncios', id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar el anuncio. ' + response.e,
      };
    }
    return { ok: true, data: 'Exito al eliminar anuncio' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar anuncio. ' + error,
    };
  }
};

export const reactivateAnnouncement = async (announcementId) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/reactivar-anuncios',
      {
        id_anuncio: announcementId,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo reactivar el anuncio. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: 'Error al intentar crear anuncio. ' + error };
  }
};

export const resendAnnouncementEmails = async (idList = []) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/reenviar-correo-anuncios',
      {
        lista_id_anuncios: idList,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo reenviar los correos. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar reenviar correos. ' + error,
    };
  }
};
